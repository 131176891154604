.primary-underline {
    position: relative;
    display: inline-block;
    z-index: 0;
}

.primary-underline::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 0;
    background-color: #023B93;
    border: 2px solid #023B93;
    border-radius: 2px;
}