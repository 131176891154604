  /* For pdf */
  .react-pdf__Page {
    margin-top: 10px;
    position: relative;
    overflow-x: hidden;
  }

  .react-pdf__Page__textContent {
    width: 100% !important;
  }

  .react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    overflow-x: hidden;
  }
